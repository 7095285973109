<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Kegiatan Users Join : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="status" label-for="status">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <v-select
                id="status"
                v-model="filterBySearch.webijadwal_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option"
                placeholder="Status Kegiatan.."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="tanggal" label-for="tanggal">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="tanggal"
            >
              <b-form-input
                id="tanggal"
                placeholder="Tangal Kegiatan.."
                v-model="filterBySearch.webijadwal_tanggal"
                type="date"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- basic -->
          <b-form-group label="judul" label-for="judul">
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <b-form-input
                id="judul"
                placeholder="Judul Kegiatan.."
                v-model="filterBySearch.webijadwal_title"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalCreateSertifikats(data.item)">
            <feather-icon icon="FileTextIcon" size="16" />
            <span class="align-middle ml-50">Sertifikat</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>

    <b-modal
      id="modal-add-sertifikat"
      title="Tambah Sertifikat"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="id webinar" label-for="id webinar ">
          <validation-provider
            #default="{ errors }"
            name="id_webinar"
            rules="required"
          >
            <b-form-input
              id="id_webinar"
              v-model="formCreateSertifikat.webijadwal_id.id"
              placeholder="id webinar"
              readonly
            />
          </validation-provider>
        </b-form-group>
        <b-form-group label="title webinar" label-for="title webinar ">
          <validation-provider
            #default="{ errors }"
            name="title_webinar"
            rules="required"
          >
            <b-form-input
              id="title_webinar"
              v-model="formCreateSertifikat.webijadwal_id.webijadwal_title"
              placeholder="title webinar"
              readonly
            />
          </validation-provider>
        </b-form-group>
        <b-form-group label="id users" label-for="id users ">
          <validation-provider
            #default="{ errors }"
            name="id_users"
            rules="required"
          >
            <b-form-input
              id="id_users"
              v-model="formCreateSertifikat.orang_id.id"
              placeholder="id users"
              readonly
            />
          </validation-provider>
        </b-form-group>
        <b-form-group label="Sertifikat" label-for="sertifikat">
          <b-form-file
            multiple
            @change="handlerUploadSertifikat($event)"
            :file-name-formatter="formatNames"
            accpet="image/file*"
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="createSertifikatKegiatan"
        >
          Tambah Sertifikat
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],

      //fields list jadwal webinar
      fields: [
        { key: "id", label: "id" },
        { key: "webijadwal_id.webijadwal_title", label: "title" },
        { key: "orang_id.orang_nama_lengkap", label: "nama peserta" },
        { key: "created_at", label: "dibuat" },
        { key: "updated_at", label: "diupdate" },
        "action",
      ],
      /* eslint-disable global-require */
      items: [],

      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      formCreateSertifikat: {
        id: null,
        webijadwal_id: {
          id: null,
          webijadwal_title: null,
        },
        orang_id: {
          id: null,
        },
      },
      filterBySearch: {
        webijadwal_status: null,
        webijadwal_tanggal: null,
        webijadwal_title: null,
      },

      sertifikatHandler: "",
      option: ["pendaftaran-dibuka", "pendaftaran-ditutup", "selesai"],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getUsersJoinKegiatan();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
    modalCreateSertifikats(item) {
      this.formCreateSertifikat = item;
      this.$bvModal.show("modal-add-sertifikat");
    },
    handlerUploadSertifikat(e) {
      const { files } = e.target;
      if (files.length) {
        this.createHandlerSertifikat(files[0], (result) => {
          this.sertifikatHandler = result;
        });
      }
    },

    createHandlerSertifikat(file, cb) {
      const reader = new FileReader();

      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    async createSertifikatKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var data = {
          webinar_file: this.sertifikatHandler,
          webinar_title: this.formCreateSertifikat.webijadwal_id
            .webijadwal_title,
          webinar_id: this.formCreateSertifikat.webijadwal_id.id,
          orang_id: this.formCreateSertifikat.orang_id.id,
        };
        console.log(data);
        await API.webinar.uploadSertifikatWebinar(data);
        this.refreshPage();
        this.handleMsgSuccess("Berhasil upload sertifikat");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$bvModal.hide("modal-add-sertifikat");
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getUsersJoinKegiatan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filterBySearch,
        };
        const { data } = await API.webinar.listUsersJoinWebinar(
          form,
          this.$route.params.id_kegiatan
        );
        this.data_table = data;
        this.items = data.data;
        this.rows = data.meta.total;

        this.handleMsgSuccess("Berhasil tampilkan list users join webinar");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getUsersJoinKegiatan();
  },
  watch: {
    currentPage() {
      this.getUsersJoinKegiatan();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
